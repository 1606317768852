import type { CMSVariant } from '@/types/sanity'
import { getQtyOptions } from './get-qty-options'
import { buildSrc, buildSrcSetUrls } from '../media-transformer'
import type { MappedSKU } from '@/clients/commercelayer/apis/get-skus'
export interface MappedVariant extends Omit<CMSVariant, 'image'>, MappedSKU {
  options?: string[] | number[]
  image?: {
    src?: string
    srcset?: string
    alt?: string
  }
}

const transformImage = (image: CMSVariant['image']) => {
  if (!image?.asset?._ref) return undefined

  return {
    src: buildSrc(image, { width: 544, quality: 80, ratio: '3/4' })?.vanityUrl(),
    srcset: buildSrcSetUrls(
      image,
      { width: 544, quality: 80, ratio: '3/4' },
      [1920, 1600, 1366, 1040, 768, 640, 320, 304],
    ),
    alt: image?.alt || '',
  }
}

export const mapVariant = (variant: CMSVariant & MappedSKU): MappedVariant => {
  const available = variant?.inventory?.available || false
  const quantity = variant.doNotTrack ? 10 : variant?.inventory?.quantity

  return {
    ...variant,
    inventory: variant?.inventory && {
      ...variant.inventory,
      quantity,
    },
    image: variant?.image && transformImage(variant.image),
    options: getQtyOptions(available, quantity),
  }
}
